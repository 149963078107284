<template>
  <v-container class="pa-10" fluid>
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="ml-10 mr-10 mt-10 mb-10">
              <v-data-table
                :headers="headers"
                :items="brands"
                :server-items-length="totalItems1"
                 hide-default-footer
                sort-by="id"
                class="elevation-1"
                style="background: #f5f5f5; font-size: 10em"
              >
                <!-- 
                      :single-expand="singleExpand"
                :expanded.sync="expanded"
                show-expand
                  <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <b>brand ID : </b> {{ item.id }} <br />
                    <b>Name : </b> {{ item.Brand_name }}<br />
                    <b>Loction : </b> {{ item.brand_location }}<br />
                    <b>Quantity : </b> {{ item.spacification }}<br />
                  </td>
                </template> -->
                <template v-slot:top>
                  <v-toolbar elevation="2" style="background: #f5f5f5">
                    <v-toolbar-title
                      style="margin-left: -14px"
                      class="mt-2 mb-2 ml-2"
                      >Brand Details
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-switch
                      v-model="singleExpand"
                      label="Single expand"
                      class="mt-5"
                    ></v-switch>  v-if="item.Brand_name != 'Individual' "-->

                    <v-btn
                      class="ml-5"
                      color="primary"
                      float-right
                      @click="newbrand"
                    >
                      <v-icon color="white">mdi-plus</v-icon>Add Brand
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div v-if="item.Brand_name != 'Individual'">
                    <v-icon small class="mr-2" @click="editbrand(item)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon small @click="deletebrand(item)">mdi-delete</v-icon>
                  </div>
                </template>
              </v-data-table>
              <!-- Add brand dialog -->
              <v-dialog v-model="addbrand" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="headline">Add Brand</span>
                  </v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          outlined
                          dense
                          :rules="[(v) => !!v || 'Brand Name is required']"
                          v-model="Brand_name"
                          label="Brand Name"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          outlined
                          dense
                          :rules="[
                            (v) => !!v || 'Brand Owner Name is required',
                          ]"
                          v-model="Brand_owner"
                          label="Owner Name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          outlined
                          dense
                          :rules="[(v) => !!v || 'Brand Country is required']"
                          :items="country"
                          v-model="Brand_country"
                          label="Country Name"
                        ></v-select>
                      </v-col>
                    
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red" text @click="close">Cancel</v-btn>
                      <v-btn color="blue darken-1"  @click="confirm"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <!-- Edit brand Dialog -->
              <v-dialog v-model="editbranddialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="headline">Edit Brand Information </span>
                  </v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          outlined
                          dense
                          :rules="[(v) => !!v || 'Brand Name is required']"
                          v-model="editedItem.Brand_name"
                          label="Brand Name"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          outlined
                          dense
                          :rules="[
                            (v) => !!v || 'Brand Owner Name is required',
                          ]"
                          v-model="editedItem.Brand_owner"
                          label="Owner Name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          outlined
                          dense
                          :rules="[(v) => !!v || 'Brand Country is required']"
                          :items="country"
                          v-model="editedItem.Brand_country"
                          label="Country Name"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red" text @click="close">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="confirmedit"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <div class="d-flex justify-content-center mt-5">
                <!-- <v-col cols="3" md="3"> -->
                <div class="col-4">
                    Total {{ totalItems1 }} records
                </div>
                <!-- </v-col> -->
                <!-- <v-col cols="3" md="3"> -->
                <div class="col-4">
                    <v-select dense outlined hide-details v-model="perPage1" label="Items per page" @change="handlePageSizeChange" :items="pageSizes1" >
                    </v-select>                                                
                </div>
                <!-- </v-col> -->
                <!-- <v-col cols="6" md="3" > -->
                <div class="col-4">
                    <v-pagination v-model="currentPage1" :length="totalPages1" total-visible="7" @input="handlePageChange">
                    </v-pagination>
                </div>
                <!-- </v-col> -->
            </div>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" outlined centered color="black">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    addbrand: false,
    editbranddialog: false,
    Brand_name: "",
    perPage1: '10',
    currentPage1: 1,
    totalItems1: 0,
    totalPages1: 0,
    pageSizes1: ['10', '30', '50', '100', 'All'],
    Brand_country:"",
    Brand_owner: "",

    snackbar: "",

    headers: [
      {
        text: "Brand Name",
        align: "start",

        value: "Brand_name",
      },
      { text: "Brand Owner Name", value: "Brand_owner", sortable: false },
      { text: "Country", value: "Brand_country" },
      { text: "Created Date", value: "created_date" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    country: [
      "Bangladesh",
      "India",
      "Pakistan",
      "China",
      "Sri Lanka",
      "Malaysia",
      "Indonesia",
      "Nepal",
      "Bhutan",
      "Maldives",
      "Japan",
      "Philippines",
      "Vietnam",
      "Turkey",
      "Iran",
      "Thailand",
      "Myanmar",
      "South Korea",
      "Saudi Arabia",
      "United Arab Emirates",
      "Singapore",
      "Kuwait",
      "Qatar",
      "Brunei",
      "Bahrain",
    ],
    brands: [],
    contact: {},
    editedIndex: -1,
    editedItem: {
      Brand_name: "",
      Brand_owner: "",
      Brand_country: "",
    },
    defaultItem: {
      Brand_name: "",
      Brand_owner: "",
      Brand_country: "",
    },
    text : '',
    snackbar : false
  }),

 
  methods: {
    initialize() {
      axios.get("productdetails/get_brands/").then((response) => {
        this.brands = response.data.data;
      });
    },

    newbrand() {
      this.addbrand = true;
    },
    confirm() {
      // This api will call after add brand information
      axios
        .post("productdetails/create_brand/", {
          Brand_name: this.Brand_name,
          Brand_owner: this.Brand_owner,
          Brand_country: this.Brand_country,
        })
        .then((response) => {
     
          if (response.data.success) {
            this.brands.push(response.data.data);
            this.close();
            //this.text = "Your Brand created successfully !";
            this.text = response.data.message;
            this.snackbar = true;
          }
        });
    },

    editbrand(item) {
      this.editedItem = item;
      this.editbranddialog = true;
    },

    confirmedit(item) {
      axios
        .post(
          `productdetails/update_brand/${this.editedItem.id}/`,
          this.editedItem
        )
        .then((response) => {
           this.close();
          //this.text = "Brand Information updated successfully";
          this.text = response.data.message;
          this.snackbar = true;
        });
    },

    deletebrand(item) {
      if (confirm("Do you really want to delete?")) {
        axios
          .post(`productdetails/delete_brand/${item.id}/`)
          .then((response) => {
            this.brands = this.brands.filter(
              (contact) => contact.id !== item.id
            );
             this.text = response.data.message;
           // this.text = "Your Brand Deleted successfully !";
            this.snackbar = true;
          });
      }
    },

    close() {
      this.editbranddialog = false;
      this.addbrand = false;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>












<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>
